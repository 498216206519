import ContentContainer from "../layout/ContentContainer";
import ChatTopBar from "./ChatTopBar";
import BackButton from "components/common/buttons/basicButton/BackButton";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import searchIco from "../../assets/icons/chat-search-ico.svg";
import placeholder from "../../assets/images/new-placeholder.png";
import micIco from "../../assets/icons/chat-mic.svg";
import activeMicIco from "../../assets/icons/chat-mic-active.svg";
import imageIco from "../../assets/icons/chat-image.svg";
import sendMessageIco from "../../assets/icons/chat-send-message.svg";
import sendVoiceMessageIco from "../../assets/icons/chat-voice-message.svg";
import attachmentIco from "../../assets/icons/chat-attachment.svg";
import SingleUser from "./SingleUser";
// @ts-ignore
import Modal from "react-modal";
import logo from "../../assets/images/logo.svg";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import * as signalR from "@microsoft/signalr";
import { toast } from "react-toastify";
import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import chatServices, { getConversation } from "services/chatServices";
import {
  ModalContainer,
  ModalContent,
  ModalOverlay,
  InputWithSearchResultsWrapper,
  RightSectionAddressAndTitleWriteMode,
  SearchResultWrapper,
  SingleSearchResultWrapper,
  ChatButton,
  Container,
  ContentBox,
  FilterSelect,
  IcoBox,
  MessageBox,
  ReplyMessageBox,
  SerachInputBox,
  SideNav,
  TopBar,
  Drag,
  ButtonsBox,
  MessagesBox,
  VoiceBox,
  MessageAudioBox,
  SenderNameBox,
  SenderImageBox,
  MessagesBoxTopBar,
  Test,
  MessagesBoxTopBarButtons,
  AnnouncementsBox,
} from "./style";

// import playerProfileServices from "services/playerProfileServices";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { PlayerProfileInterface } from "types/userTypes";
import { AllConversationsContext } from "contexts/allConversationsContext";
import { useDebounce } from "use-debounce";
import playerService from "services/transfer/playerServices";
import { getAllAcademyTrainersRef } from "services/transferServices";
import jwtDecode from "jwt-decode";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import systemServices from "services/systemServices";
import Button from "components/common/buttons/basicButton/Button";

const AudioBox = styled.div<{ isPlaying: boolean }>`
  /* max-width: 600px; */
  width: 400px;
  height: 43.5px;
  display: flex;
  position: relative;

  & > button {
    min-width: 43.5px;
    height: 43.5px;
    background: ${(props) => (props.isPlaying ? "#292A31" : "#0091ff")};
    border: ${(props) => (props.isPlaying ? "1px solid #0091ff" : "none")};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  & > div {
    width: 100%;
    height: 100%;

    & > progress {
      width: 100%;
      height: 100%;
      background-color: #707070 !important;
      accent-color: blue;
      ::-moz-progress-bar {
        background: rgba(0, 145, 255, 0.8) !important;
      }

      ::-webkit-progress-value {
        background: rgba(0, 145, 255, 0.8) !important;
      }
      ::-webkit-progress-bar {
        background: #424242 !important;
        /* dasdsa */
      }
    }
  }

  & > p {
    position: absolute;
    top: 12px;
    right: 24px;
    color: white;
    font-weight: 400;
    font-size: 15px;
  }

  & > audio {
    /* display: none; */
  }
`;

const AudioPlayer: React.FC<{ filePath: string; position: string }> = ({
  filePath,
  position,
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    console.log("audiomax", audioRef.current?.duration);
  }, [audioRef.current?.duration]);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    if (audioRef.current) {
      if (!isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const formatTime = (timeInSeconds: number): string => {
    const minutes: number = Math.floor(timeInSeconds / 60);
    const seconds: number = Math.floor(timeInSeconds % 60);
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  if (position === "right") {
    return (
      <AudioBox isPlaying={isPlaying}>
        <audio
          ref={audioRef}
          src={filePath}
          // type="audio/x-caf"
          onTimeUpdate={handleTimeUpdate}
          onEnded={() => setIsPlaying(false)}
        />
        <button onClick={togglePlay}>
          {isPlaying ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                id="Icon_open-media-stop"
                data-name="Icon open-media-stop"
                d="M0,0V18H18V0Z"
                fill="#0091ff"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
            >
              <path
                id="Icon_open-media-play"
                data-name="Icon open-media-play"
                d="M0,0V21L21,10.5Z"
                fill="#fff"
              />
            </svg>
          )}
        </button>
        <div>
          <progress
            max={audioRef.current?.duration || 10}
            value={currentTime}
          ></progress>
        </div>
        <p>Wiadomość głosowa - {formatTime(currentTime)} sek</p>
      </AudioBox>
    );
  } else {
    return (
      <AudioBox isPlaying={isPlaying}>
        <audio
          ref={audioRef}
          src={filePath}
          // type="audio/wav"
          onTimeUpdate={handleTimeUpdate}
          onEnded={() => setIsPlaying(false)}
        />

        <div>
          <progress
            max={audioRef.current?.duration || 10}
            value={currentTime}
          ></progress>
        </div>
        <p style={{ left: "24px" }}>
          Wiadomość głosowa - {formatTime(currentTime)} sek
        </p>
        <button onClick={togglePlay}>
          {isPlaying ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                id="Icon_open-media-stop"
                data-name="Icon open-media-stop"
                d="M0,0V18H18V0Z"
                fill="#0091ff"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
            >
              <path
                id="Icon_open-media-play"
                data-name="Icon open-media-play"
                d="M0,0V21L21,10.5Z"
                fill="#fff"
              />
            </svg>
          )}
        </button>
      </AudioBox>
    );
  }
};

interface Conversation {
  Id: string;
  IsClosed: boolean;
  LastMessage: {
    Content: string;
    From: number;
    Id: string;
    Sent: string;
    Type: number;
  };
  LogoFilePath: string;
  Name: string;
  ProfileId: string;
  ProfileType: number;
}

interface File {
  Id: string;
  FilePath: string;
}

interface IMessage {
  Content: string;
  Files: File[];
  From: number;
  Id: string;
  Sent: string;
  Supporter: any;
  Type: number;
  User: {
    FirstName: string;
    LastName: string;
    Id: string;
  };
}

const Chat: React.FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const logoInputRef = useRef<HTMLInputElement>(null);
  const { goBack } = useHistory();
  // const handleCreateSupportConversation = (
  //   isOpen: boolean,
  //   singleConversation: any,
  //   imageUrl: string,
  //   id: string,
  //   name: string,
  // ) => {
  //   toggleAddNewConversation(isOpen);
  //   getSingleConversation(singleConversation);
  //   setActiveConversationImageUrl(imageUrl);
  //   setActiveConversationName(name);
  //   setActiveConversationId(id);
  // };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isParticipantsModalOpen, setIsParticipansModalOpen] = useState(false);
  const [editedConversationName, setEditedConversationName] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [hoveredId, setHoveredId] = useState<any>(null);
  const handleImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const createPrivateConversation = async (id: string) => {
    const resp: any = await chatServices.createNewConversation(
      id,
      activeMemberType,
    );

    await chatServices.sendMessage(resp, newMessageContent);
    await setNewMessageContent("");
    setTempMemberId("");
    toast.success("Stworzono prywatną konwersacje!");
  };

  const createGroupConversation = async (id: string) => {
    const resp: any = await chatServices.createNewGroupConversation(
      auxFolderName ? auxFolderName : "brak nazwy",
      "",
      allInterlocutors,
      id,
    );
    await chatServices.sendMessage(resp, newMessageContent);
    await setNewMessageContent("");
    setTempMemberId("");
    setAllInterlocutors([]);
    toast.success("Stworzono grupową konwersacje!");
  };

  const closeModal = () => {
    setSelectedImage("");
    setIsModalOpen(false);
  };

  const [
    allConversations,
    setAllConversations,
    filter,
    setFilter,
    searchQuery,
    setSearchQuery,
    conversation,
    setConversation,
    conversationMembers,
    setConversationMembers,
    activeConversationImageUrl,
    setActiveConversationImageUrl,
    activeConversationName,
    setActiveConversationName,
    onlineProfiles,
    setOnlineProfiles,
    activeConversationId,
    setActiveConversationId,
    activeConversationType,
    setActiveConversationType,
    tempMemberId,
    setTempMemberId,
    unreadMessagesCounter,
    setUnreadMessagesCounter,
    allInterlocutors,
    setAllInterlocutors,
    auxFolderName,
    setAuxFolderName,
    activeMemberType,
    setActiveMemberType,
  ] = useContext(AllConversationsContext);

  const token = localStorage.getItem("token");
  const decodedToken: any = token && jwtDecode(token);

  //TODO
  // const [activeConversationType, setActiveConversationType] = useState<any>(1);

  const textareaRef = useRef(null);
  const [newMessageContent, setNewMessageContent] = useState("");
  const [newBase64String, setNewBase64String] = useState<any>("");
  const [newLogoBase64String, setNewLogoBase64String] = useState<any>("");
  const [menuType, setMenuType] = useState(0);

  const [addNewConversation, toggleAddNewConversation] = useState(false);

  const [receiver, setReceiver] = useState("");
  const [receiverParticipants, setReceiverParticipants] = useState("");
  const [activeMessageId, setActiveMessageId] = useState("");
  const [activeMessageContent, setActiveMessageContent] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [receiverIdParticipants, setReceiverIdParticipants] = useState("");
  const [isFocused, toggleFocused] = useState(false);
  const [isFocusedParticipants, toggleFocusedParticipants] = useState(false);

  const scrollableDivRef: any = useRef(null);
  const [isConversationNameEdited, setIsConversationNameEdited] =
    useState(false);
  const [initialPos, setInitialPos] = useState<any>(null);
  const [initialSize, setInitialSize] = useState<any>(null);
  const resizableRef = useRef(null);
  const draggableRef = useRef(null);
  //TODO
  // const [isMenuOpen, toggleMenuOpen] = useState(true);
  const [isMenuOpen, toggleMenuOpen] = useState(false);
  const [isVoiceBoxOpen, setIsVoiceBoxOpen] = useState(false);

  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] =
    useState<MediaRecorder | null>(null);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [progress, setProgress] = useState(0);
  const audioStreamRef = useRef<MediaStream | null>(null);
  const audioElementRef = useRef<HTMLAudioElement | null>(null);

  const handleRecordingFinish = async () => {
    if (!recording && audioBlob) {
      const base64Audio = await encodeAudioToBase64(audioBlob);
      const fileUrl = await systemServices.uploadFile(base64Audio);
      chatServices.sendVoiceMessage(activeConversationId, fileUrl);
    }
  };

  const readConversation = (ConversationId: string) => {
    chatServices.readMessage(ConversationId);
  };

  const profileTypeSwitch = (type: number) => {
    switch (type) {
      case 0:
        return "Brak";
      case 1:
        return "Zawodnik";
      case 2:
        return "Trener";
      case 3:
        return "Akademia";
      default:
        return type;
    }
  };

  useEffect(() => {
    handleRecordingFinish();
  }, [audioBlob]);

  const encodeAudioToBase64 = (audioBlob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(audioBlob);
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          const base64Audio = reader.result.split(",")[1];
          resolve(base64Audio);
        } else {
          reject(new Error("Error encoding audio to base64"));
        }
      };
    });
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        let chunks: Blob[] = [];
        recorder.ondataavailable = (e) => {
          chunks.push(e.data);
          setProgress(chunks.length);
        };
        recorder.onstop = () => {
          const audioBlob = new Blob(chunks, { type: "audio/wav" });
          setAudioBlob(audioBlob);
        };
        recorder.start();
        setMediaRecorder(recorder);
        setRecording(true);
        audioStreamRef.current = stream;
      })
      .catch((err) => console.error("Error accessing microphone:", err));
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
      if (audioStreamRef.current) {
        audioStreamRef.current.getTracks().forEach((track) => track.stop());
        audioStreamRef.current = null;
      }
    }
  };

  const deleteMemberFromConversation = async (
    memberId: string,
    memberType: number,
  ) => {
    await chatServices.deleteProfileFromConversation(
      activeConversationId,
      memberId,
      memberType,
    );
    await getConversationMembers();
    toast.success("Usunięto uczestnika konwersacji!");
  };

  const getConversationMembers = async () => {
    const resp: any = await chatServices.getConversationMembers(
      activeConversationId,
    );
    setConversationMembers(resp?.Items);
    console.log("respchatmembers", resp);
  };

  const playRecording = () => {
    if (!audioBlob) {
      console.error("No audio recorded");
      return;
    }

    const audioUrl = URL.createObjectURL(audioBlob);

    if (audioElementRef.current) {
      audioElementRef.current.src = audioUrl;
      audioElementRef.current.play();
    }
  };

  const scrollToBottom = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop =
        scrollableDivRef.current.scrollHeight;
    }
  };

  const initial = (e: any) => {
    const resizable: any = resizableRef.current;

    setInitialPos(e.clientX);
    if (resizable) {
      setInitialSize(resizable.offsetWidth);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [allConversations, conversation]);

  const resize = (e: any) => {
    const resizable: any = resizableRef.current;
    if (resizable) {
      const step = 20;
      const newWidth =
        parseInt(initialSize) +
        Math.round((e.clientX - initialPos) / step) * step;

      if (newWidth < 210) {
        resizable.style.width = "210";
      } else {
        resizable.style.width = `${newWidth}px`;
      }
    }
  };

  const getOnlineProfiles = async () => {
    const resp: any = await chatServices.getOnlineProfiles();
    console.log("ONLINEPROFILES", resp);
    setOnlineProfiles(resp.Items);
  };

  useEffect(() => {
    getOnlineProfiles();
  }, []);

  useEffect(() => {
    const textarea: any = textareaRef.current;
    textarea.style.height = "48px";
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [newMessageContent]);

  const onBlur = (e: any) => {
    if (!e.currentTarget.contains(e.relatedTarget)) return toggleFocused(false);
    return toggleFocused(true);
  };

  const onBlurParticipants = (e: any) => {
    if (!e.currentTarget.contains(e.relatedTarget))
      return toggleFocusedParticipants(false);
    return toggleFocusedParticipants(true);
  };

  const handleOnFocusChange = (value: boolean) => toggleFocused(value);
  const handleOnFocusChangeParticipants = (value: boolean) =>
    toggleFocusedParticipants(value);

  const handleReceiverChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setReceiver(e.target.value);

  const handleReceiverChangeParticipants = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setReceiverParticipants(e.target.value);

  const handleReceiverIdChange = (value: string) => setReceiverId(value);
  const handleReceiverIdChangeParticipants = (value: string) =>
    setReceiverIdParticipants(value);

  const handleSearchQueryChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  // const handleFilterChange = (e: any) => {
  //   setFilter(e.target.value);
  // };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleLogoButtonClick = () => {
    if (logoInputRef.current) {
      logoInputRef.current.click();
    }
  };

  const handleEditedConversationNameChange = (e: any) => {
    setEditedConversationName(e.target.value);
  };

  // useEffect(() => {
  //   if (allConversations[0]?.Id) {
  //     getSingleConversation(allConversations[0].Id);
  //     setActiveConversationImageUrl(allConversations[0].LogoFilePath);
  //     setActiveConversationName(allConversations[0].Name);
  //     setActiveConversationId(allConversations[0].Id);
  //   }
  // }, [allConversations]);

  useEffect(() => {
    if (newBase64String) {
      sendFile(
        activeConversationId,
        newBase64String.substring(newBase64String.indexOf(",") + 1),
      );
    }
  }, [newBase64String]);

  useEffect(() => {
    if (newLogoBase64String) {
      changeConversationLogoFn(activeConversationId, newLogoBase64String);
    }
  }, [newLogoBase64String]);

  const handleNewMessageChange = (e: any) => {
    setNewMessageContent(e.target.value);
  };

  const handleNewBase64Change = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setNewBase64String(base64String);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  //TODO
  useEffect(() => {
    if (activeConversationType !== 1) {
      toggleMenuOpen(false);
      setConversationMembers([]);
    }
  }, [activeConversationType]);

  useEffect(() => {
    if (activeConversationType === 1 && isMenuOpen) {
      getConversationMembers();
    }
    if (!isMenuOpen) {
      setConversationMembers([]);
    }
  }, [activeConversationId, isMenuOpen]);

  const renderMenu = (menuType: any) => {
    switch (menuType) {
      case 1:
        return <p>zdjecie</p>;
      case 2:
        return (
          <>
            <p>Nazwa grupy uczestników</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StyledInputBox>
                <StyledInput
                  type="text"
                  value={editedConversationName}
                  onChange={handleEditedConversationNameChange}
                />
                <p>Nazwa grupy</p>
              </StyledInputBox>
              <Button
                onClick={() => {
                  chatServices.changeConversationName(
                    activeConversationId,
                    editedConversationName,
                  );
                }}
                style={{ width: "280px" }}
                variant={ButtonVariant.Submit}
              >
                Zastosuj
              </Button>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <p>LISTA UCZESTNIKÓW</p>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {conversationMembers.length > 0 &&
                  conversationMembers.map((singleMember: any) => {
                    const { Id, Name, PhotoFilePath, Type } = singleMember;
                    return (
                      // <SingleConversationMember key={Id}>
                      //   {
                      //     <img
                      //       src={PhotoFilePath ? PhotoFilePath : placeholder}
                      //       alt=""
                      //     />
                      //   }

                      //   <p>{Name}</p>
                      //   <svg
                      //     style={{ cursor: "pointer" }}
                      //     onClick={() => deleteMemberFromConversation(Id, Type)}
                      //     xmlns="http://www.w3.org/2000/svg"
                      //     width="12"
                      //     height="15.429"
                      //     viewBox="0 0 12 15.429"
                      //   >
                      //     <path
                      //       id="Icon_material-delete"
                      //       data-name="Icon material-delete"
                      //       d="M8.357,18.214a1.719,1.719,0,0,0,1.714,1.714h6.857a1.719,1.719,0,0,0,1.714-1.714V7.929H8.357ZM19.5,5.357h-3L15.643,4.5H11.357l-.857.857h-3V7.071h12Z"
                      //       transform="translate(-7.5 -4.5)"
                      //       fill="#f8f8f8"
                      //     />
                      //   </svg>

                      // </SingleConversationMember>
                      <MemberContainer key={Id}>
                        <img
                          src={PhotoFilePath ? PhotoFilePath : placeholder}
                          alt=""
                        />
                        <UserInfoBox>
                          <p>{Name}</p>
                          <p>{profileTypeSwitch(Type)}</p>
                        </UserInfoBox>
                        {decodedToken?.AcademyId !== Id && (
                          <svg
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              deleteMemberFromConversation(Id, Type)
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="15.429"
                            viewBox="0 0 12 15.429"
                          >
                            <path
                              id="Icon_material-delete"
                              data-name="Icon material-delete"
                              d="M8.357,18.214a1.719,1.719,0,0,0,1.714,1.714h6.857a1.719,1.719,0,0,0,1.714-1.714V7.929H8.357ZM19.5,5.357h-3L15.643,4.5H11.357l-.857.857h-3V7.071h12Z"
                              transform="translate(-7.5 -4.5)"
                              fill="#f8f8f8"
                            />
                          </svg>
                        )}
                      </MemberContainer>
                    );
                  })}
              </div>
            </div>

            <p>DODAWANIE UCZESTNIKÓW</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
                background: "transparent",
              }}
            >
              <InputWithSearchResultsWrapper
                onBlur={onBlurParticipants}
                // style={{ marginBottom: "10px" }}
              >
                <RightSectionAddressAndTitleWriteMode
                  style={{
                    background: "rgba(163,158,157,0.11)",
                    height: "48px",
                    width: "280px",
                    paddingLeft: "15px",
                  }}
                >
                  <img
                    src={searchIco}
                    alt="ico"
                    style={{ width: "21px", height: "21px" }}
                  />
                  <input
                    type="text"
                    placeholder="Wyszukaj..."
                    autoComplete="off"
                    value={receiverParticipants}
                    onFocus={() => handleOnFocusChangeParticipants(true)}
                    onChange={handleReceiverChangeParticipants}
                  />
                </RightSectionAddressAndTitleWriteMode>
                <SearchResultWrapper
                  isFocused={isFocusedParticipants}
                  autoCompleted={receiverParticipants.length > 1}
                  tabIndex={0}
                >
                  {playerProfilesParticipants.length === 0 &&
                    trainerProfilesParticipants.length === 0 &&
                    receiverParticipants.length > 1 && (
                      <SingleSearchResultWrapper
                        style={{
                          color: "white",
                          fontSize: "13px",
                          fontWeight: 500,
                          background: "rgba(163,158,157,0.11)",
                        }}
                      >
                        <p> Brak wyników</p>
                      </SingleSearchResultWrapper>
                    )}

                  {playerProfilesParticipants.map((playerProfile) => {
                    const { PlayerId, Name } = playerProfile;

                    return (
                      <SingleSearchResultWrapper
                        style={{
                          color: "white",
                          fontSize: "13px",
                          fontWeight: 500,
                          background: "rgba(163,158,157,0.11)",
                        }}
                        key={PlayerId}
                        onClick={() => {
                          handleReceiverIdChangeParticipants(PlayerId);
                          setReceiverParticipants(`${Name}`);
                          toggleFocusedParticipants(false);
                          joinProfileToConversation(PlayerId, 1);
                        }}
                      >
                        <p>{`${Name}`}</p>
                      </SingleSearchResultWrapper>
                    );
                  })}

                  {trainerProfilesParticipants.map((trainerProfile) => {
                    const { TrainerId, Name } = trainerProfile;

                    return (
                      <SingleSearchResultWrapper
                        key={TrainerId}
                        onClick={() => {
                          handleReceiverIdChangeParticipants(TrainerId);
                          setReceiverParticipants(`${Name}`);
                          toggleFocusedParticipants(false);
                          joinProfileToConversation(TrainerId, 2);
                        }}
                      >
                        <p>{`${Name}`}</p>
                      </SingleSearchResultWrapper>
                    );
                  })}
                </SearchResultWrapper>
              </InputWithSearchResultsWrapper>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const handleLogoNewBase64Change = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String: any = reader.result;
      setNewLogoBase64String(base64String.split(",")[1]);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "80%",
      maxHeight: "80%",
      width: "500px",
      height: "300px",
      background: "rgba(0, 0, 0, 0.8)",
    },
  };

  const customParticipantsStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "60%",
      maxHeight: "60%",
      width: "60%",
      height: "60%",
      background: "#1a2530",
      // background: "rgba(0, 0, 0, 1)",
      margin: 0,
      padding: 0,
      // border: "2px solid lightgray",
      // borderTop: "30px solid lightgray",
    },
  };

  // const handleNewBase64Change = async (e: any) => {
  //   const file = e.target.files[0];
  //   const base64 = await convertBase64(file);
  //   console.log(base64);
  //   setNewBase64String(base64);
  // };

  const sendMessage = async (ConversationId: string, Content: string) => {
    if (activeConversationId && newMessageContent) {
      chatServices.sendMessage(ConversationId, Content);
      setNewMessageContent("");
    }
  };

  const replyToMessage = async (ConversationId: string, Content: string) => {
    if (activeConversationId && newMessageContent) {
      chatServices.replyToMessage(ConversationId, activeMessageId, Content);
      setNewMessageContent("");
      setActiveMessageContent("");
      setActiveMessageId("");
    }
  };

  const closeFolderEditNameModal = () => {
    setIsConversationNameEdited(false);
  };

  const closeParticipantsModal = () => {
    setIsParticipansModalOpen(false);
  };

  const unselectConversation = () => {
    setActiveConversationImageUrl("");
    setActiveConversationId("");
    setActiveConversationType(null);
    setActiveConversationName("");
    setConversation([]);
  };

  const sendFile = async (ConversationId: string, Base64String: string) => {
    if (activeConversationId && newBase64String) {
      const image = await systemServices.uploadFile(Base64String);
      await chatServices.sendFile(ConversationId, image);
      setNewBase64String("");
    }
  };

  const changeConversationLogoFn = async (
    ConversationId: string,
    Base64String: string,
  ) => {
    if (activeConversationId && newLogoBase64String) {
      const fileUrl = await systemServices.uploadFile(Base64String);
      chatServices.changeConversationLogo(ConversationId, fileUrl);
      setNewLogoBase64String("");
      toast.success("Zmieniono logo konwersacji!");
    }
  };

  const deleteMessage = async (messageId: string) => {
    await chatServices.removeMessage(messageId, activeConversationId);
    toast.success("Usunięto wiadomość!");
  };

  const deletePrivateConversation = async (ConversationId: string) => {
    await chatServices.removeConversation(ConversationId);
    toast.success("Usunięto konwersacje!");
    setActiveConversationId("");
    setActiveConversationImageUrl("");
    setActiveConversationName("");
    setActiveConversationType(null);
    setConversation([]);
  };

  const deleteGroupConversation = async (ConversationId: string) => {
    await chatServices.removeGroupConversation(ConversationId);
    toast.success("Usunięto konwersacje grupową!");
  };

  const leaveConversation = async (ConversationId: string) => {
    await chatServices.leaveConversation(ConversationId);
    toast.success("Opuszczono konwersacje!");
  };

  const createNewConversation = async (
    ProfileId: string,
    ProfileType: number,
  ) => {
    const resp2: any = await chatServices.getPrivateConversationById(
      ProfileId,
      ProfileType,
    );
    console.log("RESP2>>>", resp2);
    const detail = await playerService.getDetail(ProfileId);

    if (resp2 === null) {
      setActiveConversationId("");
      setActiveConversationImageUrl(detail.PhotoFilePath);
      setActiveConversationName(detail.Name);
      setActiveConversationType(1);
      setConversation([]);
      setTempMemberId(ProfileId);
      toggleAddNewConversation(false);
      // chatServices.createNewConversation(ProfileId, ProfileType);
    } else {
      toast.error("Konwersacja z użytkownikiem jest już aktywna!");
    }

    // const activeConv = allConversations.filter(
    //   (single: any) => !single.IsClosed && single.ProfileId === ProfileId,
    // );
    // console.log("test>@#@", activeConv);
    // if (activeConv.length > 0) {
    //   toast.error("Konwersacja z wybranym użytkownikiem jest aktywna!");
    // } else {
    //   chatServices.createNewConversation(ProfileId, ProfileType);
    // }
  };

  const joinProfileToConversation = async (
    ProfileId: string,
    ProfileType: number,
  ) => {
    const isMember = conversationMembers.find(
      (member: any) => member.Id === ProfileId,
    );

    if (!isMember) {
      chatServices.joinProfileToConversation(
        activeConversationId,
        ProfileId,
        ProfileType,
      );
      // toast.success("Dodano nowego uczestnika do konwersacji!");
    } else {
      toast.error("Użytkownik należy do konwersacji!");
    }
  };

  const getSingleConversation = async (ConversationId: string) => {
    // const token = localStorage.getItem("token");
    // const resp: any = await axios.get(
    //   `https://adminapi.justwin.pl/api/Conversation/Messages?ConversationId=${ConversationId}`,
    //   {
    //     headers: {
    //       Authorization: `bearer ${token}`,
    //     },
    //   },
    // );
    const resp: any = await getConversation(ConversationId);
    // console.log("singleconv>>", resp);
    console.log("singleconv>", resp.Items);
    setConversation(resp.Items);
  };

  useEffect(() => {
    // if (conversation.length === 0 && allConversations.length > 0) {
    if (allConversations.length > 0 && !tempMemberId && !activeConversationId) {
      getSingleConversation(allConversations[0]?.Id);
      toggleAddNewConversation(false);

      setActiveConversationImageUrl(allConversations[0]?.LogoFilePath);
      setActiveConversationName(allConversations[0]?.Name);
      setActiveConversationType(allConversations[0]?.Type);
      setActiveConversationId(allConversations[0]?.Id);
      readConversation(allConversations[0]?.Id);
    }
  }, [allConversations, tempMemberId]);

  useEffect(() => {
    if (allConversations.length > 0 && !tempMemberId) {
      const conversation = allConversations.find(
        (conversation: any) => conversation.Id === activeConversationId,
      );
      if (conversation) {
        setActiveConversationImageUrl(conversation.LogoFilePath);
        setActiveConversationName(conversation.Name);
        setActiveConversationType(conversation.Type);
        getSingleConversation(activeConversationId);
        readConversation(activeConversationId);
      }
    }
  }, [activeConversationId, tempMemberId]);

  // const {
  //   items: playerProfiles,
  //   loading,
  //   containerRef,
  //   lastItemRef,
  // } = useInfiniteScroll<PlayerProfileInterface>(
  //   playerProfileServices.getAllPlayerProfiles,
  //   receiver,
  // );

  const [debounceSearchInput] = useDebounce(receiver.trim(), 500);
  const [debounceSearchInputParticipants] = useDebounce(
    receiverParticipants.trim(),
    500,
  );

  const {
    items: playerProfiles,
    loading,
    containerRef,
    lastItemRef,
    refresh,
  } = useInfiniteScroll<any>(playerService.getAcademy, debounceSearchInput);

  const {
    items: trainerProfiles,
    loading: trainerLoading,
    containerRef: trainerContainerRef,
    lastItemRef: trainerLastItemRef,
    refresh: trainerRefresh,
    // @ts-ignore
  } = useInfiniteScroll<any>(getAllAcademyTrainersRef, debounceSearchInput);

  const {
    items: playerProfilesParticipants,
    loading: playerLoadingParticipants,
    containerRef: playerContainerRefParticipants,
    lastItemRef: playerLastItemRefParticipants,
    refresh: playerRefreshParticipants,
  } = useInfiniteScroll<any>(
    playerService.getAcademy,
    debounceSearchInputParticipants,
  );

  const {
    items: trainerProfilesParticipants,
    loading: trainerLoadingParticipants,
    containerRef: trainerContainerRefParticipants,
    lastItemRef: trainerLastItemRefParticipants,
    refresh: trainerRefreshParticipants,
  } = useInfiniteScroll<any>(
    // @ts-ignore
    getAllAcademyTrainersRef,
    debounceSearchInputParticipants,
  );

  return (
    <>
      {isModalOpen && (
        <ModalContainer>
          <ModalOverlay onClick={closeModal}></ModalOverlay>
          <ModalContent>
            <img src={selectedImage} alt="Zdjęcie powiększone" />
          </ModalContent>
        </ModalContainer>
      )}

      <SideMenu isMenuOpen={isMenuOpen}>
        {activeConversationType === 1 && (
          <div>
            <p>NARZĘDZIA</p>
            <div>
              <div
                onClick={() => {
                  leaveConversation(activeConversationId);
                  unselectConversation();
                }}
              >
                <p>Opuść grupę</p>
              </div>
              <div
                onClick={() => {
                  deleteGroupConversation(activeConversationId);
                  unselectConversation();
                }}
              >
                <p>Usuń konwersację</p>
              </div>
              <GridSingleItem
                onClick={handleLogoButtonClick}
                // onClick={() => setMenuType(1)}
                menuType={menuType}
                menuTypeIndex={1}
              >
                <p>Zdjęcie</p>
              </GridSingleItem>
              <input
                type="file"
                onChange={handleLogoNewBase64Change}
                ref={logoInputRef}
                style={{ display: "none" }}
              />
              <GridSingleItem
                // onClick={() => setIsConversationNameEdited(true)}
                onClick={() => setMenuType(2)}
                menuType={menuType}
                menuTypeIndex={2}
              >
                <p>Nazwa</p>
              </GridSingleItem>
              <GridSingleItem
                // onClick={() => {
                //   setIsParticipansModalOpen(true);
                //   getConversationMembers();
                // }}
                onClick={() => {
                  setMenuType(3);
                  getConversationMembers();
                }}
                menuType={menuType}
                menuTypeIndex={3}
              >
                <p>Uczestnicy</p>
              </GridSingleItem>
            </div>
            {renderMenu(menuType)}
          </div>
        )}
      </SideMenu>
      <TopBar>
        <BackButton
          className="w-11 h-11 z-10 hover:opacity-100 shadow p-0 flex-shrink-0"
          style={{ marginLeft: "15px" }}
          variant={ButtonVariant.Submit}
          onClick={() => goBack()}
        >
          <i>
            <Arrow className="fill-current w-7" />
          </i>
        </BackButton>
        <div>Czaty</div>
        {/* <div>
          {addNewConversation && (
            <InputWithSearchResultsWrapper
              onBlur={onBlur}
              // style={{ marginBottom: "10px" }}
            >
              <RightSectionAddressAndTitleWriteMode>
                <img src={logo} alt={logo} />
                <input
                  type="text"
                  placeholder="Wpisz nazwe uczestnika"
                  autoComplete="off"
                  value={receiver}
                  onFocus={() => handleOnFocusChange(true)}
                  onChange={handleReceiverChange}
                />
              </RightSectionAddressAndTitleWriteMode>
              <SearchResultWrapper
                isFocused={isFocused}
                autoCompleted={receiver.length > 1}
                tabIndex={0}
              >
                {playerProfiles.length === 0 &&
                  trainerProfiles.length === 0 &&
                  receiver.length > 1 && (
                    <p
                      style={{
                        color: "white",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      Brak wyników
                    </p>
                  )}

                {playerProfiles.map((playerProfile) => {
                  const { PlayerId, Name } = playerProfile;

                  return (
                    <SingleSearchResultWrapper
                      key={PlayerId}
                      onClick={() => {
                        handleReceiverIdChange(PlayerId);
                        setReceiver(`${Name}`);
                        toggleFocused(false);
                        createNewConversation(PlayerId, 1);
                        toggleAddNewConversation(false);
                      }}
                    >
                      <p>{`${Name}`}</p>
                    </SingleSearchResultWrapper>
                  );
                })}

                {trainerProfiles.map((trainerProfile) => {
                  const { TrainerId, Name } = trainerProfile;

                  return (
                    <SingleSearchResultWrapper
                      key={TrainerId}
                      onClick={() => {
                        handleReceiverIdChange(TrainerId);
                        setReceiver(`${Name}`);
                        toggleFocused(false);
                        createNewConversation(TrainerId, 2);
                        toggleAddNewConversation(false);
                      }}
                    >
                      <p>{`${Name}`}</p>
                    </SingleSearchResultWrapper>
                  );
                })}
              </SearchResultWrapper>
            </InputWithSearchResultsWrapper>
          )}
          {activeConversationName && (
            <>
              {
                <img
                  src={
                    activeConversationImageUrl
                      ? activeConversationImageUrl
                      : placeholder
                  }
                  alt=""
                />
              }

              <p>{activeConversationName}</p>
            </>
          )}
        </div> */}
      </TopBar>
      <Container>
        {/* <SideNav ref={resizableRef} id="Resizable"> */}
        <SideNav>
          <Drag
          // ref={draggableRef}
          // id="Draggable"
          // draggable="true"
          // onDragStart={initial}
          // onDrag={resize}
          />

          <SerachInputBox>
            <>
              <div>
                <img src={searchIco} alt="ico" />
              </div>
              <input
                type="text"
                placeholder="Wyszukaj..."
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
            </>
          </SerachInputBox>

          {/* <div>
            <button onClick={recording ? stopRecording : startRecording}>
              {recording ? "Stop" : "Record"}
            </button>
            <button onClick={handleSend} disabled={!audioChunks.length}>
              Send
            </button>
            <button onClick={playRecording} disabled={!audioChunks.length}>
              Play
            </button>
            <audio ref={audioElementRef} controls />
            <progress value={progress} max={RECORDING_LIMIT} />
          </div> */}
          <div style={{ width: "100%", display: "flex", gap: "6px" }}>
            <FilterSelect
            // onChange={handleFilterChange}
            >
              <option value="">Wszystkie</option>
              {/* @ts-ignore */}
              <option value={false}>aktywne </option>
              {/* @ts-ignore */}

              <option value={true}>zarchiwizowane </option>
            </FilterSelect>
            <ChatButton
              onClick={() => {
                toggleAddNewConversation((prev) => !prev);
                unselectConversation();
              }}
              style={{
                width: "100%",
                background: "#737072",
              }}
            >
              Nowa rozmowa
            </ChatButton>
          </div>

          <hr />
          <div>
            {allConversations &&
              allConversations.map((conversation: any) => {
                const {
                  Id,
                  IsClosed,
                  LastMessage,
                  LogoFilePath,
                  Name,
                  Type,
                  Members,
                } = conversation;

                const todayDateString = new Date().toISOString().slice(0, 10);

                const messageDate = LastMessage?.Sent?.slice(0, 10);
                const isToday = messageDate === todayDateString;

                const dateMessage = isToday
                  ? LastMessage?.Sent?.slice(11, 16)
                  : `${LastMessage?.Sent?.slice(
                      0,
                      10,
                    )} ${LastMessage?.Sent?.slice(11, 16)}`;

                const onlineUser: any = onlineProfiles.find(
                  (profile: any) => profile.Id === Id,
                );
                const isActive = Members?.some((member: any) => {
                  const onlineUser = onlineProfiles.find(
                    (profile: any) => profile.Id === member.Id,
                  );
                  return onlineUser && onlineUser.IsOnline;
                });

                return (
                  <SingleUser
                    image={LogoFilePath}
                    handleClick={getSingleConversation}
                    toggleAddNewConversation={toggleAddNewConversation}
                    id={Id}
                    key={Id}
                    type={Type}
                    isActive={isActive}
                    name={Name}
                    message={LastMessage?.Content}
                    date={dateMessage}
                    activeConversationId={activeConversationId}
                    setActiveConversationName={setActiveConversationName}
                    setActiveConversationId={setActiveConversationId}
                    readConversation={readConversation}
                    setActiveConversationType={setActiveConversationType}
                    setActiveMemberType={setActiveMemberType}
                    setActiveConversationImageUrl={
                      setActiveConversationImageUrl
                    }
                  />
                );
              })}
          </div>
        </SideNav>
        <ContentBox>
          <MessagesBoxTopBar>
            {addNewConversation && (
              <Test>
                <InputWithSearchResultsWrapper
                  onBlur={onBlur}
                  // style={{ marginBottom: "10px" }}
                >
                  <RightSectionAddressAndTitleWriteMode>
                    <img src={logo} alt={logo} />
                    <input
                      type="text"
                      placeholder="Wpisz nazwe uczestnika"
                      autoComplete="off"
                      value={receiver}
                      onFocus={() => handleOnFocusChange(true)}
                      onChange={handleReceiverChange}
                    />
                  </RightSectionAddressAndTitleWriteMode>
                  <SearchResultWrapper
                    isFocused={isFocused}
                    autoCompleted={receiver.length > 1}
                    tabIndex={0}
                  >
                    {playerProfiles.length === 0 &&
                      trainerProfiles.length === 0 &&
                      receiver.length > 1 && (
                        <p
                          style={{
                            color: "white",
                            fontSize: "13px",
                            fontWeight: 500,
                          }}
                        >
                          Brak wyników
                        </p>
                      )}

                    {playerProfiles.map((playerProfile) => {
                      const { PlayerId, Name } = playerProfile;

                      return (
                        <SingleSearchResultWrapper
                          key={PlayerId}
                          onClick={() => {
                            handleReceiverIdChange(PlayerId);
                            setReceiver(`${Name}`);
                            toggleFocused(false);
                            createNewConversation(PlayerId, 1);
                            toggleAddNewConversation(false);
                          }}
                        >
                          <p>{`${Name}`}</p>
                        </SingleSearchResultWrapper>
                      );
                    })}

                    {trainerProfiles.map((trainerProfile) => {
                      const { TrainerId, Name } = trainerProfile;

                      return (
                        <SingleSearchResultWrapper
                          key={TrainerId}
                          onClick={() => {
                            handleReceiverIdChange(TrainerId);
                            setReceiver(`${Name}`);
                            toggleFocused(false);
                            createNewConversation(TrainerId, 2);
                            toggleAddNewConversation(false);
                          }}
                        >
                          <p>{`${Name}`}</p>
                        </SingleSearchResultWrapper>
                      );
                    })}
                  </SearchResultWrapper>
                </InputWithSearchResultsWrapper>
              </Test>
            )}
            {activeConversationName && (
              <Test>
                {
                  <img
                    src={
                      activeConversationImageUrl
                        ? activeConversationImageUrl
                        : placeholder
                    }
                    alt=""
                  />
                }

                <p>{activeConversationName}</p>
              </Test>
            )}
            {activeConversationName && (
              <MessagesBoxTopBarButtons>
                <IcoBox
                  onClick={() => {
                    if (activeConversationType === 1) {
                      deleteGroupConversation(activeConversationId);
                      unselectConversation();
                    } else {
                      deletePrivateConversation(activeConversationId);
                      unselectConversation();
                    }
                  }}
                  isClicked={false}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="21.857"
                    viewBox="0 0 17 21.857"
                  >
                    <path
                      id="Icon_material-delete"
                      data-name="Icon material-delete"
                      d="M8.714,23.929a2.436,2.436,0,0,0,2.429,2.429h9.714a2.436,2.436,0,0,0,2.429-2.429V9.357H8.714ZM24.5,5.714H20.25L19.036,4.5H12.964L11.75,5.714H7.5V8.143h17Z"
                      transform="translate(-7.5 -4.5)"
                      fill="#f8f8f8"
                    />
                  </svg>
                </IcoBox>

                {activeConversationType === 1 && (
                  <IcoBox
                    onClick={() => toggleMenuOpen((prev) => !prev)}
                    isClicked={isMenuOpen}
                  >
                    {isMenuOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24.621"
                        height="24.1"
                        viewBox="0 0 24.621 24.1"
                      >
                        <path
                          id="Icon_metro-tools"
                          data-name="Icon metro-tools"
                          d="M29.354,9.578a6.13,6.13,0,0,1-8.689,7.69l-1.7,1.852,1.21,1.215.723-.723a.945.945,0,0,1,1.338,0L28.1,25.535a.945.945,0,0,1,0,1.338l-2.676,2.676a.945.945,0,0,1-1.338,0l-5.863-5.923a.945.945,0,0,1,0-1.338l.66-.66-1.153-1.156L9.625,29.33a1.892,1.892,0,0,1-2.676,0l-.669-.669a1.892,1.892,0,0,1,0-2.676l9.237-7.736L9.382,12.1H7.445L5.2,8.491l1.8-1.808L10.695,8.94l.024,1.9,6.206,6.23,1.806-1.512A6.136,6.136,0,0,1,26,6.24l-3.976,3.921,3.346,3.346,3.983-3.928ZM8.5,26.929a.946.946,0,1,0,0,1.339A.947.947,0,0,0,8.5,26.929Z"
                          transform="translate(-5.203 -5.785)"
                          fill="#0091ff"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24.621"
                        height="24.1"
                        viewBox="0 0 24.621 24.1"
                      >
                        <path
                          id="Icon_metro-tools"
                          data-name="Icon metro-tools"
                          d="M29.354,9.578a6.13,6.13,0,0,1-8.689,7.69l-1.7,1.852,1.21,1.215.723-.723a.945.945,0,0,1,1.338,0L28.1,25.535a.945.945,0,0,1,0,1.338l-2.676,2.676a.945.945,0,0,1-1.338,0l-5.863-5.923a.945.945,0,0,1,0-1.338l.66-.66-1.153-1.156L9.625,29.33a1.892,1.892,0,0,1-2.676,0l-.669-.669a1.892,1.892,0,0,1,0-2.676l9.237-7.736L9.382,12.1H7.445L5.2,8.491l1.8-1.808L10.695,8.94l.024,1.9,6.206,6.23,1.806-1.512A6.136,6.136,0,0,1,26,6.24l-3.976,3.921,3.346,3.346,3.983-3.928ZM8.5,26.929a.946.946,0,1,0,0,1.339A.947.947,0,0,0,8.5,26.929Z"
                          transform="translate(-5.203 -5.785)"
                          fill="#f8f8f8"
                        />
                      </svg>
                    )}
                  </IcoBox>
                )}
              </MessagesBoxTopBarButtons>
            )}
          </MessagesBoxTopBar>
          <MessagesBox ref={scrollableDivRef}>
            {conversation.length > 0 &&
              conversation
                .map((message: any, index: number) => {
                  const token = localStorage.getItem("token");
                  const decodedToken: any = token && jwtDecode(token);
                  const {
                    Content,
                    Files,
                    From,
                    Id,
                    Type,
                    Sender,
                    IsDelete,
                    ActionType,
                    Reply,
                  } = message;
                  if (Content && Type === 0 && ActionType === 2) {
                    return (
                      <ReplyMessageBox
                        position={
                          Sender?.Id === decodedToken?.AcademyId
                            ? "right"
                            : "left"
                        }
                        key={Id}
                      >
                        <div
                          onMouseEnter={() => setHoveredId(index)}
                          onMouseLeave={() => setHoveredId(null)}
                        >
                          <div>{Reply?.Content && <p>{Reply.Content}</p>}</div>
                          {hoveredId === index && !IsDelete && (
                            <div>
                              {Sender?.Id === decodedToken?.AcademyId ? (
                                <svg
                                  onClick={() => deleteMessage(Id)}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21"
                                  height="27"
                                  viewBox="0 0 21 27"
                                >
                                  <path
                                    id="Icon_material-delete"
                                    data-name="Icon material-delete"
                                    d="M9,28.5a3.009,3.009,0,0,0,3,3H24a3.009,3.009,0,0,0,3-3v-18H9ZM28.5,6H23.25l-1.5-1.5h-7.5L12.75,6H7.5V9h21Z"
                                    transform="translate(-7.5 -4.5)"
                                    fill="#fff"
                                  />
                                </svg>
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  onClick={() => {
                                    setActiveMessageId(Id);
                                    setActiveMessageContent(Content);
                                    setIsVoiceBoxOpen(false);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="21"
                                    viewBox="0 0 24 21"
                                  >
                                    <path
                                      id="Icon_open-share"
                                      data-name="Icon open-share"
                                      d="M9,21V15C21,15,24,8.85,24,0c-1.56,5.94-6,9-12,9H9V3L0,12.48Z"
                                      fill="#fff"
                                    />
                                  </svg>
                                </div>
                              )}
                            </div>
                          )}

                          <pre>{Content}</pre>
                        </div>
                        {/* {Sender?.Id !== decodedToken?.AcademyId && (
                          <SenderNameBox position="left" style={{}}>
                            <p>{Sender?.Name ? Sender?.Name : "-"}</p>
                          </SenderNameBox>
                        )} */}
                        {Sender?.Id !== decodedToken?.AcademyId && (
                          <SenderImageBox position="left">
                            <img
                              src={
                                Sender?.PhotoFilePath
                                  ? Sender?.PhotoFilePath
                                  : placeholder
                              }
                              alt=""
                            />
                          </SenderImageBox>
                        )}
                      </ReplyMessageBox>
                    );
                  }
                  if (Type === 0 && ActionType === 0) {
                    return (
                      <MessageBox
                        position={
                          Sender?.Id === decodedToken?.AcademyId
                            ? "right"
                            : "left"
                        }
                        key={Id}
                      >
                        <div
                          onMouseEnter={() => setHoveredId(index)}
                          onMouseLeave={() => setHoveredId(null)}
                        >
                          {hoveredId === index && !IsDelete && (
                            <div>
                              {Sender?.Id === decodedToken?.AcademyId ? (
                                <svg
                                  onClick={() => deleteMessage(Id)}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21"
                                  height="27"
                                  viewBox="0 0 21 27"
                                >
                                  <path
                                    id="Icon_material-delete"
                                    data-name="Icon material-delete"
                                    d="M9,28.5a3.009,3.009,0,0,0,3,3H24a3.009,3.009,0,0,0,3-3v-18H9ZM28.5,6H23.25l-1.5-1.5h-7.5L12.75,6H7.5V9h21Z"
                                    transform="translate(-7.5 -4.5)"
                                    fill="#fff"
                                  />
                                </svg>
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  onClick={() => {
                                    setActiveMessageId(Id);
                                    setActiveMessageContent(Content);
                                    setIsVoiceBoxOpen(false);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="21"
                                    viewBox="0 0 24 21"
                                  >
                                    <path
                                      id="Icon_open-share"
                                      data-name="Icon open-share"
                                      d="M9,21V15C21,15,24,8.85,24,0c-1.56,5.94-6,9-12,9H9V3L0,12.48Z"
                                      fill="#fff"
                                    />
                                  </svg>
                                </div>
                              )}
                            </div>
                          )}

                          <pre>{Content ? Content : "Wiadomość usunięta"}</pre>
                        </div>
                        {Sender?.Id !== decodedToken?.AcademyId && (
                          <SenderNameBox position="left">
                            <p>{Sender?.Name ? Sender?.Name : "-"}</p>
                          </SenderNameBox>
                        )}
                        {Sender?.Id !== decodedToken?.AcademyId && (
                          <SenderImageBox position="left">
                            <img
                              src={
                                Sender?.PhotoFilePath
                                  ? Sender?.PhotoFilePath
                                  : placeholder
                              }
                              alt=""
                            />
                          </SenderImageBox>
                        )}
                      </MessageBox>
                    );
                  }

                  if (Type === 1) {
                    return (
                      <MessageBox
                        position={
                          Sender?.Id === decodedToken?.AcademyId
                            ? "right"
                            : "left"
                        }
                        key={Id}
                      >
                        {Files.map((singleFile: any) => {
                          const { FilePath, FileId } = singleFile;
                          const imageSrc = FilePath.startsWith("http")
                            ? FilePath
                            : `data:image/jpeg;base64,${FilePath}`;
                          return (
                            <img
                              key={FileId}
                              src={imageSrc}
                              alt={FileId}
                              onClick={() => handleImageClick(imageSrc)}
                            />
                          );
                        })}
                        {Sender?.Id !== decodedToken?.AcademyId && (
                          <SenderNameBox position="left">
                            <p>{Sender?.Name ? Sender?.Name : "-"}</p>
                          </SenderNameBox>
                        )}
                        {Sender?.Id !== decodedToken?.AcademyId && (
                          <SenderImageBox position="left">
                            <img
                              src={
                                Sender?.PhotoFilePath
                                  ? Sender?.PhotoFilePath
                                  : placeholder
                              }
                              alt=""
                            />
                          </SenderImageBox>
                        )}
                      </MessageBox>
                    );
                  }
                  if (Type === 2) {
                    return (
                      <MessageAudioBox
                        position={
                          Sender?.Id === decodedToken?.AcademyId
                            ? "right"
                            : "left"
                        }
                        key={Id}
                      >
                        {Files.map((singleFile: any) => {
                          const { FilePath, FileId } = singleFile;
                          return (
                            <>
                              {/* <audio controls key={FileId}>
                                <source src={FilePath} type="audio/wav" />
                                Your browser does not support the audio element.
                              </audio> */}
                              <AudioPlayer
                                key={FileId}
                                filePath={FilePath}
                                position={
                                  Sender?.Id === decodedToken?.AcademyId
                                    ? "right"
                                    : "left"
                                }
                              />
                            </>
                          );
                        })}
                        {Sender?.Id !== decodedToken?.AcademyId && (
                          <SenderNameBox position="left">
                            <p>{Sender?.Name ? Sender?.Name : "-"}</p>
                          </SenderNameBox>
                        )}
                        {Sender?.Id !== decodedToken?.AcademyId && (
                          <SenderImageBox position="left">
                            <img
                              src={
                                Sender?.PhotoFilePath
                                  ? Sender?.PhotoFilePath
                                  : placeholder
                              }
                              alt=""
                            />
                          </SenderImageBox>
                        )}
                      </MessageAudioBox>
                    );
                  }
                  if (ActionType === 0 && Type === 3) {
                    return (
                      <AnnouncementsBox key={Id}>
                        <div>
                          <p>{Content}</p>
                          <p>{`${message?.Sent?.slice(
                            0,
                            10,
                          )} ${message?.Sent?.slice(11, 16)} `}</p>
                        </div>
                      </AnnouncementsBox>
                    );
                  }
                })
                .reverse()}
          </MessagesBox>

          {activeMessageId && (
            <ReplyBox>
              <div>
                <p>ODPOWIADANIE</p>

                <svg
                  onClick={() => {
                    setActiveMessageId("");
                    setActiveMessageContent("");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <path
                    id="Icon_ionic-md-close"
                    data-name="Icon ionic-md-close"
                    d="M23.523,9.123l-1.6-1.6-6.4,6.4-6.4-6.4-1.6,1.6,6.4,6.4-6.4,6.4,1.6,1.6,6.4-6.4,6.4,6.4,1.6-1.6-6.4-6.4Z"
                    transform="translate(-7.523 -7.523)"
                    fill="#0091ff"
                  />
                </svg>
              </div>
              <div>
                <p>{activeMessageContent ? activeMessageContent : "-"}</p>
              </div>
            </ReplyBox>
          )}
          <ButtonsBox>
            <input
              type="file"
              onChange={handleNewBase64Change}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
            <IcoBox
              onClick={() => {
                setIsVoiceBoxOpen((prev) => !prev);
                setActiveMessageId("");
                setActiveMessageContent("");
              }}
              isClicked={isVoiceBoxOpen}
              // onClick={() =>
              //   sendFile(
              //     activeConversationId,
              //     newBase64String.substring(newBase64String.indexOf(",") + 1),
              //   )
              // }
            >
              <img
                src={isVoiceBoxOpen ? activeMicIco : micIco}
                alt={"sendVoiceMessage"}
              />
            </IcoBox>
            <IcoBox
              isClicked={false}
              onClick={handleButtonClick}
              // onClick={() =>
              //   sendFile(
              //     activeConversationId,
              //     newBase64String.substring(newBase64String.indexOf(",") + 1),
              //   )
              // }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.375"
                height="21"
                viewBox="0 0 18.375 21"
              >
                <path
                  id="Icon_metro-file-picture"
                  data-name="Icon metro-file-picture"
                  d="M20.249,20.3H7.124V17.678l3.938-6.563,5.394,6.563,3.794-2.625Zm0-8.531A1.969,1.969,0,1,1,18.28,9.8,1.969,1.969,0,0,1,20.249,11.772Zm1.76-5.146a18.6,18.6,0,0,0-1.788-2.045,18.6,18.6,0,0,0-2.045-1.788,3.271,3.271,0,0,0-1.865-.865H6.139A1.642,1.642,0,0,0,4.5,3.569V21.287a1.642,1.642,0,0,0,1.641,1.641H21.233a1.642,1.642,0,0,0,1.641-1.641V8.491a3.271,3.271,0,0,0-.865-1.865ZM19.293,5.509a17.9,17.9,0,0,1,1.488,1.669H17.624V4.021a17.882,17.882,0,0,1,1.669,1.488Zm2.269,15.778a.333.333,0,0,1-.328.328H6.139a.333.333,0,0,1-.328-.328V3.569a.333.333,0,0,1,.328-.328H16.311V7.834a.656.656,0,0,0,.656.656h4.594Z"
                  transform="translate(-4.499 -1.928)"
                  fill="#f8f8f8"
                />
              </svg>
            </IcoBox>

            {isVoiceBoxOpen ? (
              <VoiceBox>
                <div onClick={recording ? stopRecording : startRecording}>
                  {recording ? (
                    <svg
                      height="30px"
                      id="Layer_1"
                      version="1.1"
                      viewBox="0 0 512 512"
                      width="30px"
                    >
                      <g>
                        <path d="M224,435.8V76.1c0-6.7-5.4-12.1-12.2-12.1h-71.6c-6.8,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6   C218.6,448,224,442.6,224,435.8z" />
                        <path d="M371.8,64h-71.6c-6.7,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6c6.7,0,12.2-5.4,12.2-12.2V76.1   C384,69.4,378.6,64,371.8,64z" />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                    >
                      <path
                        id="Icon_open-media-play"
                        data-name="Icon open-media-play"
                        d="M0,0V21L21,10.5Z"
                        fill="#f8f8f8"
                      />
                    </svg>
                  )}
                </div>
                <div></div>
              </VoiceBox>
            ) : (
              <TextAreaBox>
                <textarea
                  value={newMessageContent}
                  onChange={handleNewMessageChange}
                  ref={textareaRef}
                  placeholder="Napisz..."
                />
              </TextAreaBox>
            )}

            <IcoBox
              isClicked={false}
              onClick={() => {
                if (activeMessageId) {
                  replyToMessage(activeConversationId, newMessageContent);
                } else if (
                  !activeConversationId &&
                  tempMemberId &&
                  activeConversationType === 1
                ) {
                  // sendMessage(activeConversationId, newMessageContent);
                  createPrivateConversation(tempMemberId);
                } else if (
                  !activeConversationId &&
                  tempMemberId &&
                  activeConversationType === 2
                ) {
                  // sendMessage(activeConversationId, newMessageContent);
                  createGroupConversation(tempMemberId);
                } else {
                  sendMessage(activeConversationId, newMessageContent);
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23.414"
                height="23.414"
                viewBox="0 0 23.414 23.414"
              >
                <g
                  id="Icon_feather-send"
                  data-name="Icon feather-send"
                  transform="translate(-2 -1.586)"
                >
                  <path
                    id="Path_21488"
                    data-name="Path 21488"
                    d="M28.05,3,16.5,14.55"
                    transform="translate(-4.05)"
                    fill="none"
                    stroke="#f8f8f8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                  <path
                    id="Path_21489"
                    data-name="Path 21489"
                    d="M24,3,16.65,24l-4.2-9.45L3,10.35Z"
                    fill="none"
                    stroke="#f8f8f8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </g>
              </svg>
            </IcoBox>
          </ButtonsBox>
        </ContentBox>
        <div
          style={{
            width: "412px",
            minWidth: "412px",
            height: "100%",
          }}
        ></div>
      </Container>
    </>
  );
};

export default Chat;

const SideMenu = styled.div<{ isMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 60px - 49px - 9px);
  height: calc(100vh - 60px);
  top: 60px;
  padding: ${(props) => (props.isMenuOpen ? "23px 5px" : "23px 0px")};
  right: 0;
  width: ${(props) => (props.isMenuOpen ? "150px" : "0px")};
  width: ${(props) => (props.isMenuOpen ? "417px" : "0px")};
  background: rgba(163, 158, 157, 0.11);
  background: rgba(2, 2, 15, 0.9);
  transition: all 0.25s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isMenuOpen ? "1" : "0")};
    transition: all 0.125s ease-in-out;
  }

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > p {
      text-align: center;
      font-size: 13px;
      font-weight: 400;
      color: #a39e9d;
      text-transform: uppercase;
    }

    & > div:nth-of-type(1) {
      width: 100%;
      display: grid;
      row-gap: 6px;
      column-gap: 6px;
      grid-template-columns: 50%;

      & > div:nth-of-type(1) {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        font-size: 15px;
        background: rgba(115, 112, 114, 0.3);
        grid-column-start: 0;
        grid-column-end: 1;
        cursor: pointer;
      }

      & > div:nth-of-type(2) {
        width: 100%;
        display: flex;
        height: 48px;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        font-size: 15px;
        background: rgba(115, 112, 114, 0.3);
        grid-column-start: 1;
        grid-column-end: 2;
        cursor: pointer;
      }

      & > div:nth-of-type(3) {
        width: 100%;
        display: flex;
        height: 48px;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        font-size: 15px;
        background: transparent;
        border: 1px solid rgb(112, 112, 112);
        grid-column-start: 0;
        grid-column-end: 1;
        cursor: pointer;
      }

      & > div:nth-of-type(4) {
        width: 100%;
        display: flex;
        height: 48px;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        font-size: 15px;
        background: transparent;
        border: 1px solid rgb(112, 112, 112);
        grid-column-start: 1;
        grid-column-end: 2;
        cursor: pointer;
      }

      & > div:nth-of-type(5) {
        width: 100%;
        display: flex;
        height: 48px;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        font-size: 15px;
        background: transparent;
        border: 1px solid rgb(112, 112, 112);
        grid-column-start: 0;
        grid-column-end: span 2;
        cursor: pointer;
      }
    }
  }
`;

const GridSingleItem = styled.div<{ menuType: number; menuTypeIndex: number }>`
  border: ${(props) =>
    props.menuType === props.menuTypeIndex
      ? "1px solid #0091FF !important"
      : "1px solid rgb(112, 112, 112) !important"};
  color: ${(props) =>
    props.menuType === props.menuTypeIndex
      ? "#0091FF !important"
      : "white !important"};
`;

const ReplyBox = styled.div`
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  /* background: red; */
  width: 100%;
  top: -75px;
  left: 0;
  height: 75px;

  & > div:nth-of-type(1) {
    color: white;
    display: flex;
    align-items: flex-end;
    position: relative;
    width: 100%;
    height: 27px;
    padding: 8px;
    background: rgb(2, 2, 15);

    & > p {
      position: absolute;
      top: 3px;
      left: 20px;
      font-size: 13px;
      color: #707070;
    }

    & > svg {
      position: absolute;
      top: 5px;
      right: 20px;
      font-size: 16px;
      color: #0091ff;
    }
  }

  & > div:nth-of-type(2) {
    color: white;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 48px;
    padding: 0 20px;
    background: rgba(0, 145, 255, 0.3);

    & > p {
      font-size: 13px;
      color: white;
    }
  }
`;

const TextAreaBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  & > div {
    position: absolute;
    left: 0;
    top: -54px;
    width: 100%;
    height: 49px;
    background: rgba(163, 158, 157, 0.2);
    border: 1px solid gray;
  }

  & > textarea {
    width: 100%;
    padding: 13px 20px;
    color: white;
    font-size: 15px;
    height: 48px;
    /* border-radius: 10px; */
    letter-spacing: 0.15px;
    font-weight: 500;

    background: rgba(163, 158, 157, 0.11);
    background: transparent;
    outline: none;
    max-height: 500px;
    overflow-y: hidden;
    resize: none;
    &::placeholder {
      opacity: 1;
      color: white;
    }
  }
`;

const SingleConversationMember = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  gap: 20px;

  & > img {
    height: 60px;
    width: 60px;
  }

  & > p {
    color: rgb(248, 248, 248);
    font-size: 18px;
    font-weight: 400;
    width: 200px;
  }
`;
const StyledInputBox = styled.div`
  position: relative;

  & > p {
    position: absolute;
    top: 3px;
    left: 7px;
    font-size: 11px;
    font-weight: 400;
    color: #707070;
  }
`;

const StyledInput = styled.input`
  width: 280px;
  background: transparent;
  height: 48px;
  border: 1px solid rgb(44, 44, 44);
  border-radius: 5px;
  text-align: right;
  padding: 0 10px;
  color: white;
`;

const MemberContainer = styled.div`
  /* width: calc(100% - 65px); */
  position: relative;
  width: 240px;
  height: 50px;
  /* margin-left: 16px; */
  background: rgba(156, 176, 196, 0.11);
  /* background: rgb(10, 63, 97);
  background: linear-gradient(
    0deg,
    rgba(10, 63, 97, 0.5) 0%,
    rgba(156, 176, 196, 0.5) 100%
  ); */
  border: 1px solid rgba(0, 145, 255, 0.3);
  /* &:hover {
    border: 1px solid #0091ff;
  } */
  border-radius: 7px;
  display: flex;
  align-items: center;
  gap: 15px;

  & > img {
    width: 50px;
    height: 50px;
    padding-left: 1px;
    height: 95%;
    user-select: none;
    border-radius: 7px;
    /* -webkit-box-shadow: -3px 3px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -3px 3px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: -3px 3px 5px 0px rgba(0, 0, 0, 0.5); */
  }

  & > svg {
    position: absolute;
    right: -25px;
    top: 17px;
  }

  & > p {
    color: rgba(248, 248, 248, 0.7);
    font-weight: lighter;
  }
`;

const UserInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;

  & > p:nth-of-type(1) {
    font-size: 12px;
    color: white;
    line-height: 10px;
    margin-top: 5px;
  }

  & > p:nth-of-type(2) {
    font-size: 12px;
    line-height: 13px;
    color: rgb(0, 145, 255);
  }
`;
