import TabContent from 'components/common/tabs/TabContent';
import { useOrderDetailTabsContext } from '../../hook/useOrderDetailTabsContext';
import HeaderContainer from 'components/common/Containers/HeaderContainer';
import OrderDocumentsTopbar from './OrderDocumentsTopbar';
import OrderDocumentsTable from './OrderDocumentsTable';
import InpostForm from 'components/marketpleace/Products/form/InpostForm';
import { useEffect, useState } from 'react';
import { ILabelObject } from 'types/marketpleace/order.interface';
import Modal from "react-modal";

const OrderDocuments = () => {
  const { data: order, refresh } = useOrderDetailTabsContext();
  const [isActive, setIsActive] = useState(false);
  const [labelObj, setLabelObj] = useState<ILabelObject | null>(null);
  const [isBtnDownloadActive , setIsBtnDownloadActive ] = useState(false);

  const openLabelForm = (value: boolean): void => {
    setIsActive(!isActive)
  }

  const customStylesModal = {
    content: {
      inset: '50% auto auto 50%',
      border: '1px solid rgb(204, 204, 204)',
      background: 'rgb(2, 2, 15)',
      overflow: 'auto',
      borderRadius: '4px',
      outline: 'none',
      padding: '2%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '80%',
      width: '95%',
    },
  };

  useEffect(() => {
    if (order) {
      const objLabel: ILabelObject = {
        UserId: order.OrderId,
        SellerId: order.Seller.SellerId ,
        OrderId: order.OrderId,
        Delivery: {
          DeliveryId: order.Delivery.DeliveryId,
          Name: order.Delivery.Name,
          City: order.Delivery.City,
          Region: order.Delivery.Region,
          Street: order.Delivery.Street,
          BuildingNumber: order.Delivery.BuildingNumber,
          FlatNumber: order.Delivery.FlatNumber,
          PostCod: order?.Delivery?.PostCode,
          FirstName: order.Delivery.FirstName,
          LastName: order.Delivery.LastName,
          Phone: order?.Buyer?.Phone,
          Email: order?.Buyer?.Email,
        },
        Invoice: {
          CompanyName: order.Invoice?.CompanyName,
          City: order.Invoice?.City,
          Street: order.Invoice?.Street,
          BuildingNumber: order.Invoice?.BuildingNumber,
          FlatNumber: order.Invoice?.FlatNumber,
          NIP: order.Invoice?.NIP,
          PostCode: order.Invoice?.PostCode,
          Phone: order.Buyer?.Phone,
          Email: order.Buyer?.Email,
        },
      };

      setLabelObj(objLabel);
    }
  }, [order]);

  const closePlayerModalOpen = () => {
    setIsActive(false);
  };

  const activateDownloadLabelBtn = () => {
    setIsBtnDownloadActive(true)
  }

  return (
    <TabContent id='documents'>
      <div className='flex gap-4 p-24 w-full'>
        <HeaderContainer
          title='Dokumenty'
          headerContent={
            <OrderDocumentsTopbar
              orderId={order.OrderId}
              refresh={refresh}
              openLabelForm={openLabelForm}
              isBtnDownloadActive={isBtnDownloadActive}
            />
          }>
          <Modal
            isOpen={isActive}
            style={customStylesModal}
            onRequestClose={closePlayerModalOpen}
          >
              {labelObj ? (
                <InpostForm objLabel={labelObj} openLabelForm={openLabelForm} activateDownloadLabelBtn={activateDownloadLabelBtn} />
                ) : null}
          </Modal>
          <OrderDocumentsTable
            orderId={order.OrderId}
            documents={order.Documents}
            refresh={refresh}
          />
        </HeaderContainer>
      </div>
    </TabContent>
  );
};

export default OrderDocuments;
