import ContentContainer from 'components/layout/ContentContainer';
import AcademyInfo from './AcademyInfo';
import AcademyTopbar from './AcademyTopbar';
import InfoBoxPlaceholder from 'components/common/boxes/InfoBox/InfoBoxPlaceholder';
import { createContext, useEffect, useState } from 'react';
import { showServerErrors } from 'utils/errorsUtils';
import { getAcademy, getBasicAcademy, getAcademyDetailData } from 'services/academyServices';
import { IAcademy, IAcademyMembersCount } from 'types/academy.interface';
import AcademyTabs from './tabs/AcademyTabs';
import { ITabsContext } from 'types/globalTypes';
import { useSelector } from 'react-redux';
import sellerServices from 'services/sellerServices';

export const AcademyTabsContext = createContext<ITabsContext<IAcademy>>(null!);

const Academy = () => {
  const token = localStorage.getItem('token');

  const [academy, setAcademy] = useState<IAcademy| null>(null);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const isAdmin = permission[0]?.Id === -1;

  const academyBasicInformation = isAdmin ? true : !!permission.find((permission: any) => permission.Id === 130);

  const academyDetailIsActive = isAdmin ? true : !!permission.find((permission: any) => permission.Id === 123);

  const isSellerActive = isAdmin ? true : !!permission.find((permission: any) => permission.Id === 125);

  const getAcademyData = async () => {
    try {
      let academyData = null;
      if (isAdmin) {
        academyData = await getAcademy();
      }

      if (!isAdmin && academyBasicInformation && !academyDetailIsActive) {
        academyData =  await getBasicAcademy();
      }

      if (!isAdmin && academyBasicInformation && academyDetailIsActive) {
        academyData = await getAcademyDetailData();
      }

      if (isSellerActive && !isAdmin) {
        academyData = await sellerServices.getBasicSeller();
      }

      if (academyData) {
        setAcademy(academyData);
      } else {
        setAcademy(null);
      }
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  useEffect(() => {
    if (token) {
      getAcademyData();
    } else {
      setAcademy(null);
    }
  }, [token, permission]);

  if (!academy) {
    return <InfoBoxPlaceholder />;
  }

  return (
    <ContentContainer
      title={academy.Name}
      TopBar={<AcademyTopbar academy={academy} refresh={getAcademyData} />}
      path='/'>
      <div>
        <AcademyInfo academy={academy} refresh={getAcademyData} />

        <AcademyTabsContext.Provider
          value={{ data: academy, refresh: getAcademyData }}>
          <AcademyTabs />
        </AcademyTabsContext.Provider>
      </div>
    </ContentContainer>
  );
};

export default Academy;
