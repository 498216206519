import DataTable, {
  IDataTableRows,
} from 'components/common/dataTable/DataTable';
import { format } from 'date-fns';
import { sortPassesByListSort } from 'middleware/pass/sortPassesByListSort';
import { ITableProps } from 'types/globalTypes';
import {
  IPass,
  IPassTransaction,
  PASSES_SORT_LIST,
  PASS_TRANSACTION_STATUS,
} from 'types/transfer/pass.interface';
import { getMonthDifference } from 'utils/dateUtils';



const PassesTable = ({
  containerRef,
  data,
  isDataLoading,
  lastItemRef,
  sortBy,
  detailPassPermission
}: ITableProps<IPassTransaction, PASSES_SORT_LIST>) => {
  const sortedtransactions = sortPassesByListSort(data, sortBy?.value);

  const headers = [
    'Nr zamówienia',
    'Zawodnik',
    'Typ karnetu',
    'Data początkowa',
    'Data końcowa',
    'Wartość',
    'Status',
  ];

  const rows = sortedtransactions.map(
    (transaction: IPassTransaction) =>
      ({
        data: { link: `/players/detail/${transaction.Buyer.PlayerId}` },
        cols: [
          transaction.Number,
          transaction.Buyer.Name,
          transaction.Pass.Name,
          transaction.Period.StartAt
            ? format(new Date(transaction.Period.StartAt), 'dd-MM-yyyy')
            : '-',
          transaction.Period.EndAt
            ? format(new Date(transaction.Period.EndAt), 'dd-MM-yyyy')
            : '-',
          `${transaction.Price.Gross} zł`,
          PASS_TRANSACTION_STATUS[transaction.Status],
        ],
      } as IDataTableRows)
  );

  return (
    <div
      style={{
        pointerEvents: detailPassPermission ? "auto" : "none"
      }}
    >
      <DataTable
        rows={rows}
        headers={headers}
        isDataLoading={isDataLoading}
        containerRef={containerRef}
        lastItemRef={lastItemRef}
    />
    </div>


  );
};

export default PassesTable;
