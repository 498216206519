import { conn } from 'api/BaseConnection';
import api from './axiosConfig';
import {
  ACADEMY_ACCESS_STATUS,
  ACADEMY_VISIBILITY,
  IAcademy,
  IUpdateAcademyPrimaryData,
  IUpdateAcademyUnion,
} from 'types/academy.interface';

const endpoint = conn.endpoints.adacemy;

export const getAllAcademyUsers = async () => {
  try {
    const response = await api.get('/api/Academy/Users');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAllAcademyUsersRef = (pageInfo: any) => {
  return conn.getJSON('/api/Academy/Users', 'json', { ...pageInfo });
};

export const getAllAcademyUsersContact = async () => {
  try {
    const response = await api.get('/api/Academy/UsersContact');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAcademy = async (): Promise<IAcademy> => {
  return await conn.getJSON<IAcademy>(endpoint.get, 'json');
};

export const getBasicAcademy = async():Promise<any> => {
  return await conn.getJSON<any>(endpoint.basicAcademy, 'json');
}

export const getAcademyDetailData = async():Promise<any> => {
  return await conn.getJSON<any>(endpoint.detailAcademyData, 'json');
}

export const changeAcademyVisibility = async (
  visibility: ACADEMY_VISIBILITY
) => {
  return await conn.putJSON(endpoint.changeVisibility, 'json', {
    NewAcademyVisibility: visibility,
  });
};

export const updateAcademyPrimaryData = async (
  PrimaryData: IUpdateAcademyPrimaryData
) => {
  return await conn.putJSON(endpoint.update.primaryData, 'json', PrimaryData);
};

export const updateAcademyUnion = async (Union: IUpdateAcademyUnion) => {
  return await conn.putJSON(endpoint.update.union, 'json', Union);
};

export const updateAcademyAccessState = async (
  AccessState: ACADEMY_ACCESS_STATUS
) => {
  return await conn.putJSON(endpoint.update.accessState, 'json', {
    NewTransferAcceptanceStatus: AccessState,
  });
};
