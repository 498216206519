import { conn } from 'api/BaseConnection';
import { IInpostPayload } from 'types/inpost.interface';

const endpoint = conn.endpoints.inpost;

  export const generateInpostLabel = async (
   order: IInpostPayload
  ) => {

    return await conn.postJSON(endpoint.addLabel, 'json',
      order
    );
  };

  export const getInpostLabel = (id: string) => {
    return conn.getJSON(`${endpoint}/${id}`, "json");
  };