import React, { Dispatch, SetStateAction } from 'react';
import Button from '../buttons/basicButton/Button';
import { ButtonVariant } from '../buttons/buttonTypes';
import TableHeader from './TableHeader';
import { toast } from 'react-toastify';

interface IFormTableHeaderProps {
  header: string;
  isAddFormDisplayed: boolean;
  displayAddForm: Dispatch<SetStateAction<boolean>>;
  isEditMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  editDisabled: boolean;
  canEdit?: boolean;
  canAdd?: boolean;
  isPassEditPermissionActive?: boolean;
  isPassAddPermissionActive?: boolean;
}

const FormTableHeader = ({
  header,
  displayAddForm,
  editDisabled,
  isAddFormDisplayed,
  isEditMode,
  setEditMode,
  canEdit = true,
  canAdd = true,
  isPassEditPermissionActive,
  isPassAddPermissionActive
}: IFormTableHeaderProps) => {

  return (
    <div className='border-solid border-l-4 border-orange'>
      <TableHeader header={header}>
        {canAdd && (
          <div
          onClick={() => {
            if (!isPassEditPermissionActive) {
              toast.error("Nie masz uprawnień. Skontaktuj się z Administratorem")
            }
          }}
          >
          <div
            style={{
              pointerEvents: isPassEditPermissionActive ? 'auto' : 'none',
              opacity: isPassEditPermissionActive ? '' : '0.6',
            }}
          >
            <Button
              onClick={() => displayAddForm(prev => !prev)}
              disabled={isEditMode}
              variant={ButtonVariant.Abort}>
              {!isAddFormDisplayed ? 'Dodaj' : 'Anuluj'}
            </Button>
          </div>
          </div>
        )}

        {canEdit && (
          <div
            onClick={() => {
              if (!isPassAddPermissionActive) {
                toast.error("Nie masz uprawnień. Skontaktuj się z Administratorem")
              }
            }}
          >
            <div
              style={{
                pointerEvents: isPassAddPermissionActive ? 'auto' : 'none',
                opacity: isPassAddPermissionActive ? '' : '0.6',
              }}
            >
              <Button
                onClick={() => setEditMode(prev => !prev)}
                disabled={isAddFormDisplayed || editDisabled}
                variant={ButtonVariant.Submit}>
                {isEditMode ? 'Zakończ' : 'Edytuj'}
              </Button>
            </div>
          </div>
        )}
      </TableHeader>
    </div>
  );
};

export default FormTableHeader;
