import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import cs from "classnames";
import NewLogo from "../../assets/images/Academiq-logo.png";
import NavbarItemList from "./NavbarItemList";
import MenuLogo from "../../assets/images/poweredby-justwin.png";
import { RootState } from "../../store/store";
import useDetectOutsideClick from "../../hooks/useOutsideDetectClick";
import ProfileItem from "./ProfileItem";
import config from "../../../package.json";
import LogOutIco from "../../assets/icons/logout.svg";
import { signOut } from "../../store/actions/auth";
import { useDispatch } from "react-redux";
import Notifications from "./Notifications";
import Messages from "./Messages";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-toastify";
import Chats from "./Chats";

const Navbar: React.FC = () => {
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const navbarRef = useRef(null);
  const { auth, currentUser } = useSelector((state: RootState) => state);
  const [isMessagesOpen, toggleMessagesOpen] = useState(false);
  const [isNotificationOpen, toggleNotificationOpen] = useState(false);
  const permission = useSelector((state: any) => state.permissions.permissions);

  const handleMenuExpand = (value: boolean) => {
    setIsExpanded(value);
  };
  const { push } = useHistory();
  const handleSignOut = async () => {
    await dispatch(signOut());
    push("/login");
  };

  useEffect(() => {
    handleMenuExpand(false);
  }, [pathname]);

  useDetectOutsideClick(navbarRef, () => handleMenuExpand(false));

  const containerClassNames = cs(
    "flex flex-row-reverse justify-between md:flex-col md:sticky top-0 md:h-screen py-8 px-8 md:pb-0 relative z-40",
    {
      "bg-white md:bg-transparent opacity-100": isExpanded,
    },
  );

  const isChatHasPermission = permission[0]?.Id === -1 ? true : !!permission.find((permission: any) => permission.Id === 215);

  return (
    <nav
      className={containerClassNames}
      ref={navbarRef}
      style={{
        background: "transparent",
      }}
    >
      {isAuth &&
        localStorage.getItem("token") &&
        // @ts-ignore
        jwtDecode(localStorage.getItem("token"))?.IsTemporaryPasswordChanged ===
        "True" ? (
        <div className="flex md:w-full md:flex-col h-full md:py-12">
          {/* <MenuButton
            isExpanded={isExpanded}
            handleMenuExpand={handleMenuExpand}
          /> */}
          <div style={{ width: "100%", padding: "0px 18px 20px 18px" }}>
            <img
              src={NewLogo}
              alt="logo"
              style={{ width: "120.5px", height: "25.8px" }}
            />
          </div>

          <NavbarItemList isExpanded={isExpanded} />

          <SvgBox>
            <Notifications />
          </SvgBox>
          <SvgBox>
            <Chats />
          </SvgBox>

          {/* <SvgBox
            onClick={() => {
              if (isChatHasPermission) {
                push("/chat")
              } else {
                toast.error("Nie masz uprawnień, skontaktuj się z Administratorem")
              }
            }
            }
            style={{
              cursor: isChatHasPermission ? "pointer" : "default"
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29.25"
              height="27"
              viewBox="0 0 29.25 27"
            >
              <g
                id="Icon_ionic-md-chatboxes"
                data-name="Icon ionic-md-chatboxes"
                transform="translate(-3.375 -4.5)"
              >
                <path
                  id="Path_21491"
                  data-name="Path 21491"
                  d="M27.531,4.5H4.05a.677.677,0,0,0-.675.574V20.136a.705.705,0,0,0,.675.606H8.086v6.25l6.337-6.25H27.531a.568.568,0,0,0,.524-.606V5.074A.538.538,0,0,0,27.531,4.5Z"
                  fill="#f8f8f8"
                  opacity={isChatHasPermission ? '1' : '0.4'}
                />
                <path
                  id="Path_21492"
                  data-name="Path 21492"
                  d="M32.09,8.93H29.813V20.642c0,1.124-.486,1.858-1.768,1.858H15.335L12.6,25.242h9.119L28.055,31.5V25.242H32.09a.58.58,0,0,0,.535-.61V9.57A.608.608,0,0,0,32.09,8.93Z"
                  fill="#f8f8f8"
                  opacity={isChatHasPermission ? '1' : '0.4'}
                />
              </g>
            </svg>

            <p
              style={{
                opacity: isChatHasPermission ? '' : '0.4'
              }}
            >wiadomości</p>
          </SvgBox> */}
          {/* <ProfileItem /> */}
          {/* <Notifications
            toggleNotificationOpen={toggleNotificationOpen}
            isMessagesOpen={isMessagesOpen}
            isNotificationOpen={isNotificationOpen}
            toggleMessagesOpen={toggleMessagesOpen}
          />
          <Messages
            toggleNotificationOpen={toggleNotificationOpen}
            isMessagesOpen={isMessagesOpen}
            isNotificationOpen={isNotificationOpen}
            toggleMessagesOpen={toggleMessagesOpen}
          /> */}
        </div>
      ) : (
        <div className="flex flex-col md:pt-36 text-lightpurple text-sm opacity-70 leading-relaxed"></div>
      )}
      {/* <Logo className="h-32 md:h-32 md:mx-auto" /> */}

      <div
        className="hidden md:flex justify-between px-12 pt-12 pb-8 text-xxs"
        style={{
          flexDirection: "column",
          color: "#909090",
          textAlign: "center",
          paddingBottom: "23px",
        }}
      >
        {/* {auth.isAuth && currentUser && ( */}
        {auth.isAuth && (
          <div
            style={{
              height: "64.5px",
              width: "calc(100% + 24px)",
              marginLeft: "-16px",
              borderBottom: "1px solid",
              borderTop: "1px solid",
              borderImage:
                "linear-gradient(to right, #b6b4b4, rgb(15, 15, 14)) 1%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
              color: "#909090",
              fontSize: "13px",
              marginBottom: "30px",
            }}
          >
            <div
              onClick={handleSignOut}
              style={{
                cursor: "pointer",
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                height: "25px",
              }}
            >
              <img src={LogOutIco} alt="" />
              <p>Wyloguj</p>
            </div>
          </div>
        )}

        <img
          src={MenuLogo}
          alt="logo"
          style={{
            maxWidth: "100%",
            height: "48px",
            margin: "0 auto",
            marginBottom: "10px",
          }}
        />
        <span className="opacity-50" style={{}}>
          MMlab2023
        </span>
        <span>wersja {config.version}</span>
      </div>
    </nav>
  );
};

export default Navbar;

export const SvgBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  gap: 11px;
  margin-top: auto;
  padding-bottom: 12px;

  & > p {
    font-size: 11px;
    font-weight: 400;
    color: white;
    text-transform: uppercase;
  }
`;
